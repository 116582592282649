import * as React from "react"
import { Link } from "gatsby"

import { 
  Layout,
  Seo
} from './../components'

import ArrowLeftIcon from './../images/svgs/arrow-left.svg'

const containerStyles = { 
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  minHeight: '90vh',
  paddingBottom: '15vh',
  textAlign: 'center',
}

const headingStyles = { 
  color: 'var(--color-background-sh3)',
}

const linkStyles = {
  textTransform: "uppercase",
  fontWeight: '600',
  textDecoration: 'none',
  fontSize: '1.6rem',
  whiteSpace: 'nowrap'
}

const arrowStyles = {
  position: 'relative',
  display: "inline-block",
  marginRight: '1rem',
  top: '0.2rem',
  fontSize: '.9em',
  maxWidth: '1.4rem'
}

const NotFoundPage = () => {
  return (
    <Layout>
      <Seo title="Page Not Found - Collective Haus"></Seo>
      <main>
        <div className="container has-text-center">
          <div style={containerStyles}>
            <h6 style={headingStyles}>404 - Page Not Found</h6>
            <p className="h1 font-serif">Wrong part of town bud.</p>
            <p>
              <Link to="/" style={linkStyles}><ArrowLeftIcon style={arrowStyles} /> Go back home</Link>
            </p>
          </div>
        </div>
      </main>
    </Layout>
  )
}

export default NotFoundPage
